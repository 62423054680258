import React from 'react';
import videojs from 'video.js'
import '../video-js.css'
// import qualitySelector from '../components/videojs-max-quality-selector';
import qualitySelector from '../components/videojs-hls-quality-selector';
import qualityLevels from 'videojs-contrib-quality-levels';
import  seekBtn from 'videojs-seek-buttons'
// import videoDVR from 'videojs-dvrseekbar'
// import videoDVR from 'videojs-dvr'


export default class Live extends React.Component {

  componentDidMount() {
    // instantiate Video.js
    videojs.registerPlugin('seekButton', seekBtn);
		videojs.registerPlugin('hlsQuality', qualitySelector);
		// videojs.registerPlugin('videoNdvr', videoDVR);
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      console.log(this.controlBar.seekToLive);
    });
    this.player.hlsQuality({ 
      displayCurrentQuality: true,
    });

    // this.player.seekButton({
    //   forward: 15,
    //   back: 15
    // });

		

		// this.player.videoNdvr();
		
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div>
        <div data-vjs-player>
          <video ref={ node => this.videoNode = node } className="video-js vjs-big-play-centered video-live"></video>
        </div>
      </div>
    )
  }
}
    
// export default Live;