import React, { Component } from 'react';
import Hls from 'hls.js';
import qualitySelector from './components/videojs-hls-quality-selector';
import qualityLevels from 'videojs-contrib-quality-levels';

var config = {
  enableWorker: true,
  maxBufferLength: 30,
  maxBufferSize: 60*1000*1000,
  maxStarvationDelay: 5,
  liveSyncDurationCount: 3,
  liveDurationInfinity: true,
};

export default class HLSSource extends Component {
  constructor(props, context) {
    super(props, context);
    this.hls = new Hls(config);
  }

  componentDidMount() {
    // `src` is the property get from this component
    // `video` is the property insert from `Video` component
    // `video` is the html5 video element

    // this.hls.registerPlugin('hlsQuality', qualitySelector);
    const { src, video } = this.props;
    // load hls video source base on hls.js
    if (Hls.isSupported()) {
      this.hls.loadSource(src);
      this.hls.attachMedia(video);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });
    }
  }

  componentWillUnmount() {
    // destroy hls video source
    if (this.hls) {
      this.hls.destroy();
    }
  }

  render() {
    return (
      <source
        src={this.props.src}
        type={this.props.type || 'application/x-mpegURL'}
      />
    );
  }
}