import React from "react";
import { Player } from 'video-react';
import HLSSource from './HLSSource';
import VideoPlayer from './components/Player';
import './bootstrap.min.css'
import '../node_modules/video-react/dist/video-react.css';

import "./App.css";

class App extends React.Component {

  state = {
    streamURL: localStorage.getItem('streamURL') || 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8'
  };

  handleChange = (event) => {
    const input = event.target;
    this.setState({ streamURL:  input.value });
  };

  handlePlay = () => {
    localStorage.setItem('streamURL', this.state.streamURL);
    window.location.reload();
  };
  
  render() {

    const videoJsOptions = {
      autoplay: false,
      muted: false,
      controls: true,
      liveui: true,
      aspectRatio: '16:9',
      // controlBar: {
      // 	volumePanel: {
      // 		inline: false
      // 	}
      // },
      fluid: true,
      responsive: true,
      sources: [
        {
          src: localStorage.getItem('streamURL') || 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8',
        },
      ],
    };

    return (
      <>
      <div>
        <div className=" navtop ">
           <div className="container d-flex">
           <img className="logo" src="./logo.png" alt=""></img>
          <p className="title text-center" style={{alignSelf: 'center', marginBottom: 0, marginLeft: '20px'}}>
            <span>Player Testing</span>
          </p>
           </div>
          
        </div>
      </div>
      <div className="container">
        <div >
          
          <div className="video test">
          <VideoPlayer id="video-area" {... videoJsOptions} />
          {/* <Player>
            <HLSSource
              isVideoChild
              src={localStorage.getItem('streamURL') || ''}
            />
          </Player> */}
          
          </div>
        </div>
        <form noValidate autoComplete="off">
            <div className="input-group mt-3">
              <input type="text" className="form-control" onChange={this.handleChange} value={this.state.streamURL} placeholder="https://example.com/test-hls/index.m3u8"></input>
              <div className="input-group-append">
                <button className="btn btn-primary" onClick={this.handlePlay} type="submit">PLAY</button>  
              </div>
            </div>
          </form>
      </div>
      </>
      
    );
  }
}
export default App;